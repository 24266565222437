<!--  -->
<template>
  <div class="group-page">
    <div class="form-info">
      <van-cell class="title-box">
        <p class="title">新建分组</p>
      </van-cell>
      <el-form
        :model="groupForm"
        :rules="groupRules"
        ref="groupForm"
        label-position="top"
        class="form"
      >
        <el-form-item label="分组名" prop="name">
          <el-input
            v-model.trim="groupForm.name"
            maxlength="20"
            placeholder="请输入分组名"
          ></el-input> </el-form-item
      ></el-form>
    </div>
    <div class="footer-buttons">
      <van-button class="pass" @click="onSubmit">完成</van-button>
    </div>
  </div>
</template>

<script>
import { addAddressBookGroup } from "@/api/email";
import { Toast } from "vant";
export default {
  data() {
    return {
      groupForm: {
        name: "",
        groupCode: "",
      },
      groupRules: {
        name: [{ required: true, message: "请输入分组名", trigger: "blur" }],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    onSubmit() {
      this.$refs.groupForm.validate((valid) => {
        if (valid) {
          addAddressBookGroup(this.groupForm).then((res) => {
            if (res) {
              let { code, msg } = res;
              if (code == 200) {
                Toast.success(msg);
                this.$router.go(-1);
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.group-page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .form-info {
    flex: 1;
    .title-box {
      background: #f0f6ff;
      font-weight: 900;
      height: 0.8rem;

      .van-cell__value {
        display: flex;
        flex-direction: row;
        .title {
          text-align: center;
          flex: 1;
        }
        .link {
          width: 0.8rem;
          text-align: center;
        }
      }
    }
    .form {
      margin-top: 0.15rem;
      ::v-deep .el-form-item {
        margin-bottom: 0rem;
      }
      ::v-deep .el-form-item__label {
        width: 100%;
        background: #f0f0f0;
        padding: 0rem 0.2rem;
      }
      ::v-deep .el-form-item__content {
        padding: 0.2rem;
        .el-input__inner {
          border: none;
        }
        .el-select,
        .el-input {
          width: 100%;
        }
      }
    }
  }
  .footer-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .pass {
      width: 5.18rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0.4rem;
      font-size: 0.26rem;
      color: #fff;
      line-height: 0.37rem;
    }
  }
}
</style>
